import React from 'react'
import { StaticImage } from "gatsby-plugin-image"
//import { useLocation } from "@reach/router"
import TwitterLogo from "../../static/images/commonImg/twitter-logo.svg"
import NoteLogo from "../../static/images/commonImg/note-logo.svg"
import UdemyLogo from "../../static/images/commonImg/udemy-logo.svg"

const BookPageHeader = () => {
   // const { pathname } = useLocation()
    return (
        <div className="book-page-header">
            <div className="bh-img-text-container">
                <div className="bh-img-container">
                    <StaticImage style={{width: "100px"}}src="../../static/images/picMyself/writer-pic-200x200.png" alt="Profile Pic" className="profile-img" placeholder="blurred" />
                    <p style={{marginTop: "10px", textAlign: "center"}}>三好アキ</p>
                </div>
                <div className="bh-text-container">
                    <p>ReactやNext.jsの最新情報、プログラミング学習に役立つ情報などをX（Twitter）でシェアしています。</p>
                        <div className="tw-ad-text">
                        <a href="/cu3tfwv45sr9jvei9nm2/?r=twitter" rel="noopener noreferrer" target="_blank"><img src={TwitterLogo} alt="instagram" className="sns-logo"/></a>
                        <p><a href="https://twitter.com/monotein_" rel="noopener noreferrer" target="_blank" style={{textDecoration: "underline"}}> https://twitter.com/monotein_</a></p>
                    </div>
                    <hr/>
                    <p>noteでも記事を書いています。</p>
                    <div className="tw-ad-text">
                        <a href="https://note.com/monotein" rel="noopener noreferrer" target="_blank"><img src={NoteLogo} alt="instagram" className="sns-logo"/></a>
                        <p><a href="https://note.com/monotein" rel="noopener noreferrer" target="_blank" style={{textDecoration: "underline"}}> https://note.com/monotein</a></p>
                    </div>
                    <hr/> 
                    <div>
                        <p>React、Next.js、TypeScriptなどのお役立ち情報や実践的コンテンツを、ビギナー向けにかみ砕いて無料配信中。<br/>登録はこちらから → <a href="/register-newsletter" rel="noopener noreferrer" target="_blank" style={{textDecoration: "underline"}}> 無料メルマガ登録</a></p>
                    </div>
                    <hr/>
                    <p>Udemyでは現在、私のJavaScript／Reactの動画教材を無料でご利用いただけます。</p>
                    <div className="tw-ad-text">
                        <a href="https://udemy.com/user/aki-miyoshi" rel="noopener noreferrer" target="_blank"><img src={UdemyLogo} alt="instagram" className="sns-logo"/></a>
                        <p><a href="https://udemy.com/user/aki-miyoshi" rel="noopener noreferrer" target="_blank" style={{textDecoration: "underline"}}> https://udemy.com/user/aki-miyoshi</a></p>
                    </div>
                </div>
            </div>
{/*            <div>
                <p>本書に関してもっと深く知りたいポイントや解消できないエラー、あるいはプログラミング学習に関するアドバイス／サポートなど、下記スキルシェアサービスで受け付けています。お気軽にお問い合わせください。</p>
                <ul>
                    <li>• ココナラ：<a href="https://coconala.com/services/2594486" rel="noopener noreferrer" target="_blank">https://coconala.com/services/2594486</a></li>
                </ul>
            </div> */}
        </div>
    )
}

export default BookPageHeader